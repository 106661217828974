'use client';

import clsx from 'clsx';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

import { isPROD } from '@/components/constants';
import { AD_SIZES_HASH, AdType } from '@/components/types';
import { ModalPlacementInterface } from '@/types/types';
import { getZoneImage } from '@/utils/ads/getZoneImage';
import { triggerURL } from '@/utils/ads/triggerURL';

// Regular expressions to match style, script, and text (html) in between
const styleRegex = /<style>(.*?)<\/style>/s; // 's' flag enables the dot (.) to match newline characters
const afterRegex = /<\/style>([\s\S]*)/s;

// Function to extract matched substrings
function extractParts(input: string) {
	const styleMatch = input.match(styleRegex);
	const htmlMatch = input.match(afterRegex);

	// Extracting matched substrings or returning empty string if not found
	const stylePart = styleMatch ? styleMatch[1] : '';
	const htmlPart = htmlMatch ? htmlMatch[1] : '';

	return [stylePart, htmlPart];
}

export interface TournamentCustomAdProps {
	className?: string;
	height?: number;
	ipAddress?: string;
	pageId: number;
	place: number;
	zoneId: string;
	width?: number;
}

interface TournamentCustomAdInfo {
	image?: ModalPlacementInterface;
	pageId: number;
	place: number;
	viewableURLCalled: boolean;
	eligibleURLCalled: boolean;
}

const TournamentCustomAd: React.FC<TournamentCustomAdProps> = ({ className, height, ipAddress, pageId, place, zoneId, width }) => {
	const adRef = useRef<HTMLDivElement>(null);

	const placementId = `${zoneId}_${pageId}_${place}`;
	const [adInfo, setAdInfo] = useState<TournamentCustomAdInfo>({
		pageId: +pageId,
		place: +place,
		viewableURLCalled: false,
		eligibleURLCalled: false
	});

	useEffect(() => {
		const fetchImage = async (): Promise<boolean> => {
			const currentUrl = location.protocol + '//' + location.host + location.pathname;
			const adSizeInfo = AD_SIZES_HASH[zoneId as AdType];
			const adSize = width && height ? `${width}x${height}` : adSizeInfo ? adSizeInfo.size : '';
			const screenWidth = screen.width;
			const screenHeight = screen.height;
			const screenPixelRatio = window.devicePixelRatio;
			const userAgent = navigator.userAgent;

			const response = await getZoneImage({
				ipAddress: ipAddress,
				pageId: adInfo.pageId,
				place: adInfo.place,
				refferingURL: currentUrl,
				screenHeight: screenHeight,
				screenPixelRatio: screenPixelRatio,
				screenWidth: screenWidth,
				size: adSize,
				userAgent: userAgent,
				zoneId: zoneId
			});

			if (response.status === 200 && response.placement) {
				const fetchedImage: ModalPlacementInterface = response.placement;
				setAdInfo((prevAdInfo) => ({
					...prevAdInfo,
					image: fetchedImage,
					place: prevAdInfo.place + 1,
					viewableURLCalled: false,
					eligibleURLCalled: false
				}));

				return true; // ad zone image fetched successfully
			}

			return false; // ad zone image fetched successfully
		};

		if (!adInfo.image) {
			fetchImage();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (!adInfo.eligibleURLCalled) {
						if (adInfo.image?.eligible_url) {
							triggerURL(adInfo.image.eligible_url);
						}
						setAdInfo((prevAdInfo) => ({ ...prevAdInfo, eligibleURLCalled: true }));
					}

					// Check if 50% or more of the target element is visible
					if (entry.intersectionRatio >= 0.5) {
						if (!adInfo.viewableURLCalled) {
							if (adInfo.image?.viewable_url) {
								triggerURL(adInfo.image.viewable_url);
							}
							setAdInfo((prevAdInfo) => ({ ...prevAdInfo, viewableURLCalled: true }));
						}
					}
				});
			},
			{ threshold: 0.5 } // Set threshold to 0.5 (50% visibility)
		);

		const adElement = adRef.current;
		if (adElement) {
			observer.observe(adElement);
		}

		return () => {
			if (adElement) {
				observer.unobserve(adElement);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adInfo.image, adInfo.viewableURLCalled, adInfo.eligibleURLCalled]);

	if (!adInfo.image) {
		return null;
	}

	const classes = clsx(
		'max-w-full rounded-xl',
		{
			'border border-solid bg-gray-400 text-white': !isPROD
		},
		className
	);

	const inputString = adInfo.image.body;

	// Extracting parts from the input string
	const [stylesPart, htmlPart] = extractParts(inputString);

	return (
		<div id={placementId} className={classes} ref={adRef}>
			<style>{stylesPart}</style>
			{isPROD && adInfo.image ? (
				<>
					{adInfo.image.is_redirectable && htmlPart ? (
						<a href={adInfo.image.redirect_url} target={adInfo.image.target} rel="nofollow">
							{parse(htmlPart)}
						</a>
					) : htmlPart ? (
						<>{parse(htmlPart)}</>
					) : null}
					{adInfo.image.accupixel_url && <img src={adInfo.image.accupixel_url} alt="" className="max-w-full" />}
				</>
			) : (
				<div className="inset-0 flex size-full items-center justify-center object-cover">ADVERTISEMENT</div>
			)}
		</div>
	);
};

export default TournamentCustomAd;
